import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import _ from 'lodash'

import Cloudinary from 'cloudinary-vue'

// https://www.npmjs.com/package/@gtm-support/vue2-gtm
import VueGtm from '@gtm-support/vue2-gtm'

// Import the plugin here
import { useAuth0 } from './libs/auth0'

import ClipboardJS from '../node_modules/clipboard/src/clipboard'

import router from './router'
import store from './store'
import App from './App.vue'

import './assets/style.scss'

// eslin-disable-next-line
const clipboard = new ClipboardJS('#copy')

clipboard.on('success', e => {
  e.clearSelection()
})

clipboard.on('error', e => {
  console.error('Action:', e.action)
  console.error('Trigger:', e.trigger)
})

// axios defaults
const axiosIns = axios.create({
  // Add your headers here
})

// custom logger
// Log levels 'debug', 'info', 'warn', 'error', 'fatal'
const logger = (logLevel, ...args) => {
  const isProduction = process.env.NODE_ENV === 'production'
  const ProductionLevels = ['error', 'fatal']
  if (isProduction && ProductionLevels.includes(logLevel)) {
    console[logLevel](...args)
  } else if (!isProduction) {
    console[logLevel](...args)
  }
}

Vue.prototype.$log = logger

Vue.prototype.$axios = axiosIns

// lodash setup
// eslint-disable-next-line no-underscore-dangle
Vue.prototype.$lod = _

// Composition API
Vue.use(VueCompositionAPI)

const Auth0Plugin = {
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$auth = useAuth0(options)
    // eslint-disable-next-line no-param-reassign
    Vue.auth = useAuth0(options)
  },
}

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  useRefreshTokens: process.env.VUE_APP_AUTH0_REFRESH_TOKEN,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})

Vue.use(Cloudinary, {
  configuration: {
    cloudName: 'mlfx',
  },
})

// https://www.npmjs.com/package/@gtm-support/vue2-gtm
Vue.use(VueGtm, {
  id: 'GTM-PJKJCDP', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  // queryParams: {
  //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x'
  // },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
})

Sentry.init({
  Vue,
  dsn: 'https://7ff6a9e0cd4f4e2284908371b7953873@o1175094.ingest.sentry.io/6271725',
  environment: process.env.NODE_ENV,
  integrations: [
    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/vue-router/
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'hermes.mlforex.com', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

Vue.config.productionTip = false

// import core styles
require('./@core/scss/core.scss')

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
