import Vue from 'vue'
import VueRouter from 'vue-router'

import { authGuard } from '../libs/auth0/authGuard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/pages/Home.vue'),
      props: true,
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/success*',
      name: 'success',
      component: () => import('@/views/pages/Success.vue'),
      props: true,
      meta: {
        pageTitle: 'Success',
        breadcrumb: [
          {
            text: 'Success',
            active: true,
          },
        ],
      },
    },
    {
      path: '/performance',
      name: 'performance',
      component: () => import('@/views/pages/Performance.vue'),
      props: true,
      meta: {
        pageTitle: 'Performance',
        breadcrumb: [
          {
            text: 'Performance',
            active: true,
          },
        ],
      },
    },
    {
      path: '/community',
      name: 'community',
      component: () => import('@/views/pages/Community.vue'),
      props: true,
      meta: {
        pageTitle: 'Community',
        breadcrumb: [
          {
            text: 'Community',
            active: true,
          },
        ],
      },
    },
    {
      path: '/journal',
      name: 'journal',
      component: () => import('@/views/pages/Journal.vue'),
      props: true,
      meta: {
        pageTitle: 'Journal',
        breadcrumb: [
          {
            text: 'Journal',
            active: true,
          },
        ],
      },
    },
    {
      path: '/media-library',
      name: 'media-library',
      component: () => import('@/views/pages/MediaLibrary.vue'),
      props: true,
      meta: {
        pageTitle: 'Media Library',
        breadcrumb: [
          {
            text: 'Media Library',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payment-failed',
      name: 'payment-failed',
      component: () => import('@/views/pages/PaymentFailed.vue'),
      props: true,
      meta: {
        pageTitle: 'Payment Failed',
        breadcrumb: [
          {
            text: 'Payment Failed',
            active: true,
          },
        ],
      },
    },
    {
      path: '/article/:title/:id',
      name: 'article',
      component: () => import('@/views/pages/ArticleSingle.vue'),
      props: true,
      meta: {
        pageTitle: 'Article',
        breadcrumb: [
          {
            text: 'Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/template',
      name: 'template',
      component: () => import('@/views/pages/Template.vue'),
      props: true,
      meta: {
        pageTitle: 'Template',
        breadcrumb: [
          {
            text: 'Template',
            active: true,
          },
        ],
      },
    },
    {
      path: '/trading',
      name: 'trading',
      component: () => import('@/views/pages/trading/Trading.vue'),
      props: true,
      meta: {
        pageTitle: 'Trading',
        breadcrumb: [
          {
            text: 'Trading',
            active: true,
          },
        ],
      },
    },
    {
      path: '/strategies',
      name: 'strategies',
      component: () => import('@/views/pages/strategies/Strategies.vue'),
      props: true,
      meta: {
        pageTitle: 'Strategies',
        breadcrumb: [
          {
            text: 'Strategies',
            active: true,
          },
        ],
      },
    },
    {
      path: '/leaderboard',
      name: 'leaderboard',
      component: () => import('@/views/pages/leaderboard/Leaderboard.vue'),
      props: true,
      meta: {
        pageTitle: 'Leaderboard',
        breadcrumb: [
          {
            text: 'Leaderboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/analysis',
      name: 'analysis',
      component: () => import('@/views/pages/analysis/Analysis.vue'),
      props: true,
      meta: {
        pageTitle: 'Analysis',
        breadcrumb: [
          {
            text: 'Analysis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/strategy/:strategyType/:instrument/:id',
      name: 'strategy',
      component: () => import('@/views/pages/strategies/StrategySingle.vue'),
      props: true,
      meta: {
        pageTitle: 'Strategy',
        breadcrumb: [
          {
            text: 'Strategy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/plot/:initialType/:tradeId',
      name: 'plotTrade',
      component: () => import('@/views/pages/analysis/PlotSingleTrade.vue'),
      props: true,
      meta: {
        pageTitle: 'Plot Trade',
        breadcrumb: [
          {
            text: 'Plot Trade',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/pages/profile/AccountProfile.vue'),
      props: true,
      redirect: { name: 'ProfileGeneral' },
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
      children: [
        {
          name: 'ProfileGeneral',
          path: 'general',
          component: () => import('@/views/pages/profile/AccountProfileGeneral.vue'),
          meta: {
            pageTitle: 'Profile General',
            breadcrumb: [
              {
                text: 'Profile',
              },
              {
                text: 'General',
                active: true,
              },
            ],
          },
        },
        {
          name: 'ProfilePlans',
          path: 'plans',
          component: () => import('@/views/pages/profile/AccountProfilePlans.vue'),
          meta: {
            breadcrumb: [
              {
                text: 'Profile',
              },
              {
                text: 'Plans',
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/pages/404/Error404.vue'),
      meta: {
        pageTitle: 'Error404',
        breadcrumb: [
          {
            text: 'Error',
            active: true,
          },
        ],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => authGuard(to, from, next))
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
