<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <div
      id="ctaWrap"
      class="cta-wrap"
    >
      <slot />
    </div>
    <component :is="layout">
      <router-view />
    </component>
    <div
      id="circleIframe"
      style="display: none;"
    >
      <div class="community-nav" />
      <iframe
        style="
        border: 0;
        padding: 0;
        margin: 0;
        position: fixed;
        top: 40px;
        box-shadow: none;
        width: 100%;
        height: calc(100vh - 40px);"
        src="https://mlfx.circle.so/c/getting-started?iframe=true"
      />
    </div>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import store from '@/store'
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import {
  watch,
  // provide,
  // ref,
  // readonly,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'
import { provideToast } from 'vue-toastification/composition'
import { POSITION } from 'vue-toastification'
// Also import the toast's css
import 'vue-toastification/dist/index.css'
// import 'vue-toastification/dist/index.css' // can customize later

const LayoutVertical = () => import('./views/layouts/LayoutVertical.vue')

export default {
  components: {
    // Layouts
    LayoutVertical,

  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    provideToast({ timeout: 2500, position: POSITION.BOTTOM_RIGHT })

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    for (let i = 0, len = colors.length; i < len; i += 1) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    for (let i = 0, len = breakpoints.length; i < len; i += 1) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
}
</script>

<style lang="scss">
 .h-100 {
   position: relative;
 }
</style>
