// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'MLFX', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImageBlack: require('@/assets/images/logo/logo.png'), // Will update logo in navigation menu (Branding)
    appLogoImageWhite: require('@/assets/images/logo/white-logo.png'),
    // eslint-disable-next-line global-require
    appFaviconImageBlack: require('@/assets/images/logo/favicon-dark.png'), // Will update favicon in navigation menu and tab (Branding)
    appFaviconImageWhite: require('@/assets/images/logo/favicon.png'),
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical',
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: true,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    enableScrollToTop: true,
  },
}
